import clsx from 'clsx'
import React from 'react'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  FlowType,
  FormField,
  FormProps,
  ImageCropProps,
} from '@/lib/types'
import DatePicker from './DatePicker'
import FileUploader from './FileUploader'
import ImageUploader from './ImageUploader'
import Review from './Review'
import Button from './tokens/Button'
import Checkbox from './tokens/Checkbox'
import ConditionalInput from './tokens/ConditionalInput'
import ImageInput from './tokens/ImageInput'
import Input from './tokens/Input'
import RadioButtons from './tokens/RadioButtons'
import Select from './tokens/Select'
import TagInput from './tokens/TagInput'

export default function Form(props: FormProps) {
  const {
    formSteps,
    fields,
    setFields,
    handleSubmit,
    handleChange,
    handleChangeWithoutEvent,
    handleCroppedImage,
    handleImageSelect,
    title,
    buttonLabel,
    currentStep,
    setCurrentStep,
    isInModal,
    additionalComponent,
    isRound,
    inputMarginStyleOverride,
    buttonStyleOverride,
    handleTagFieldChange,
    allowCancel,
    cancelLabel,
    handleCancel,
    block,
    removeFileFromList,
    reorderFileInList,
    accountHubs,
    imageFieldReset,
    inSettings = false,
    unsetMaxHeight = false,
    disableSubmit,
    hideButtons,
    isDisabled,
    submittingLabel = 'Processing',
  } = props

  const totalSteps = (formSteps && Object.keys(formSteps).length) || 0
  let isFieldRequired = false

  const activeFields = formSteps
    ? fields.filter((field: FormField) => {
        if (formSteps && currentStep !== undefined) {
          const currentStepFields = formSteps[currentStep].fields

          if (currentStepFields.includes(field.name)) {
            return field
          }
        }
      })
    : fields

  const handleNextStep = async (
    e: React.MouseEvent<HTMLElement>,
    force = false,
  ) => {
    e.preventDefault()
    e.stopPropagation()

    if (formSteps && setFields && currentStep !== undefined) {
      const canAdvance = await formSteps[currentStep].onChangeCallback(
        fields,
        setFields,
      )

      if (canAdvance === true || force) {
        if (setCurrentStep !== undefined && currentStep !== totalSteps - 1) {
          setCurrentStep(currentStep + 1)
        }
      }
    }
  }

  const handlePrevStep = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (formSteps && currentStep !== undefined) {
      if (setCurrentStep !== undefined && currentStep !== 0) {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  return (
    <div className="">
      {!isInModal && (
        <div className="flex max-h-[60px]">
          {title && <h1 className="ui-1 mb-60">{title}</h1>}
          {formSteps && currentStep !== undefined && totalSteps > 2 && (
            <h1 className="ui-1 mb-60 pl-16 text-grey-45">
              {currentStep + 1} of {totalSteps}
            </h1>
          )}
        </div>
      )}

      <form>
        <div
          className={clsx(
            // isInModal &&
            // ' no-scrollbar flex max-h-[calc(100dvh_-_196px)] flex-col overflow-scroll  md:max-h-[calc(100vh_-_285px)] ', //put back form height
            // TO-DO - fix double overflow issue
            ' no-scrollbar flex flex-col overflow-scroll ', //put back form height
            !inSettings &&
              'max-h-[calc(100dvh_-_233px)] md:max-h-[calc(100dvh_-_267px)]',
            inSettings &&
              ' max-h-[calc(100dvh_-_249px)]  md:max-h-[calc(100dvh_-_295px)] ', // adjusts for tab headings
            inSettings &&
              allowCancel &&
              `max-h-[calc(100dvh_-_266px)] md:!max-h-[calc(100dvh_-_330px)]`, // has Cancel  button on form
            unsetMaxHeight && '!max-h-[unset] border-blue-82',
          )}
        >
          <div
            className={clsx(
              isInModal && 'mb-0 flex-grow',
              additionalComponent && 'pb-[36px]',
              formSteps &&
                currentStep !== undefined &&
                formSteps[currentStep] &&
                formSteps[currentStep].hideButtons &&
                '!mb-[0px]',
              hideButtons && '!mb-[0px]',
            )}
          >
            {activeFields.map((field: FormField, index: number) => {
              const {
                name,
                label,
                type,
                placeholder,
                value,
                required,
                inputProps,
                disabled,
                hint,
                hide,
                error,
                flowType,
              } = field

              if (required && type !== 'fileUpload') {
                isFieldRequired = true
              }

              {
                switch (type) {
                  case 'fileUpload':
                    return (
                      <div
                        key={index}
                        className={clsx(currentStep !== 0 && 'mb-[28px] ')}
                      >
                        <FileUploader
                          name={name}
                          label="Upload Tracks"
                          key={index}
                          handleChange={handleChange}
                          handleNextStep={handleNextStep}
                          value={value}
                          type={type}
                          required={required}
                          inputProps={inputProps}
                          error={error}
                          removeFileFromList={removeFileFromList}
                          reorderFileInList={reorderFileInList}
                          variant={inputProps?.variant}
                          options={inputProps?.options}
                          currentStep={currentStep}
                        />
                      </div>
                    )
                  case 'text':
                  case 'textarea':
                  case 'number':
                    return (
                      <div
                        key={index}
                        className={clsx('mb-28', inputMarginStyleOverride)}
                      >
                        <Input
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          value={value}
                          required={required}
                          inputProps={inputProps}
                          disabled={disabled || disableSubmit}
                          hint={hint}
                          type={type}
                          handleChange={handleChange}
                          handleChangeWithoutEvent={handleChangeWithoutEvent}
                          error={error}
                        />
                      </div>
                    )
                  case 'checkbox':
                    return (
                      <div
                        key={index}
                        className={clsx(
                          'mb-28',
                          (disabled || disableSubmit) &&
                            'pointer-events-none opacity-20',
                          inputMarginStyleOverride,
                        )}
                      >
                        <Checkbox
                          name={name}
                          label={label}
                          handleChange={(e) =>
                            handleChange?.(
                              e as React.ChangeEvent<HTMLInputElement>,
                            )
                          }
                          value={value}
                          hide={hide}
                        />
                      </div>
                    )
                  case 'imageUpload':
                    return (
                      <div key={index}>
                        <ImageUploader
                          name={name}
                          label={label}
                          handleCroppedImage={handleCroppedImage}
                          handleChange={handleChange}
                          value={value as ImageCropProps}
                          type={type}
                          required={required}
                          inputProps={inputProps}
                          error={error}
                          isRound={isRound}
                          uploadType={inputProps?.uploadType}
                          aspect={inputProps?.aspect}
                          imageFieldReset={imageFieldReset}
                        />
                      </div>
                    )
                  //NON CROPPED IMAGE INPUT
                  case 'image':
                    return (
                      <div className="mb-28" key={index}>
                        <ImageInput
                          key={index}
                          handleImageSelect={handleImageSelect}
                          block={block}
                        />
                      </div>
                    )

                  case 'select':
                    return (
                      <div
                        key={index}
                        className={clsx('mb-28', inputMarginStyleOverride)}
                      >
                        <Select
                          name={name}
                          options={inputProps?.inputSelectOptions}
                          handleChange={(e: React.ChangeEvent) =>
                            handleChange?.(
                              e as React.ChangeEvent<HTMLSelectElement>,
                            )
                          }
                          value={value}
                          variant="standard"
                          placeholder={placeholder}
                          required={required}
                          label={label}
                          type={type}
                          inputProps={inputProps}
                          flowType={FlowType.Article}
                          error={error}
                          hint={hint}
                          hide={hide}
                        />
                      </div>
                    )
                  case 'conditional':
                    return (
                      <div
                        key={index}
                        className={clsx(inputMarginStyleOverride)}
                      >
                        <ConditionalInput
                          label={label}
                          name={name}
                          inputProps={inputProps}
                          placeholder={placeholder}
                          handleChange={handleChange}
                          required={required}
                          type={type}
                          value={value}
                          fields={activeFields}
                          hint={hint}
                          error={error}
                          disabled={disabled || disableSubmit}
                        />
                      </div>
                    )

                  case 'radio':
                    return (
                      <div key={index} className={clsx('mb-28')}>
                        <RadioButtons
                          name={name}
                          label={label}
                          required={required}
                          hint={hint}
                          value={value}
                          inputProps={inputProps}
                          type={type}
                          handleChange={handleChange}
                        />
                      </div>
                    )
                  case 'review':
                    return (
                      <div key={index} className="mb-20 lg:mb-28">
                        {flowType && (
                          <Review
                            type={flowType}
                            fields={fields}
                            accountHubs={accountHubs}
                          />
                        )}
                      </div>
                    )
                  case 'date':
                    return (
                      <div key={index}>
                        <DatePicker value={value} handleChange={handleChange} />
                      </div>
                    )
                  case 'tags':
                    return (
                      <div key={index}>
                        <TagInput
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          handleChange={handleChange}
                          error={error}
                          handleTagFieldChange={handleTagFieldChange}
                          value={value}
                          type="tags"
                          required={required}
                        />
                      </div>
                    )
                  default:
                    return
                }
              }
            })}
            {additionalComponent && (
              <div className="mb-28">{additionalComponent}</div>
            )}
            {isFieldRequired && (
              <p className="ui-1 md:pb-[32px]">* required fields</p>
            )}
          </div>

          <div
            className={clsx(
              isInModal &&
                // 'z-[1] w-full justify-between gap-x-12 bg-white sm:sticky sm:bottom-0 md:pb-0 md:pt-20',
                'sticky z-[1] mt-[40px] flex w-full justify-between gap-x-12  bg-white pb-[0px] pt-[20px] xs:bottom-0',
              !isInModal && 'mt-60',
              allowCancel && '  flex flex-col',
              'mt-[24px] flex   sm:mt-[40px] md:mt-0 md:pt-[20px]',
              formSteps &&
                currentStep !== undefined &&
                formSteps[currentStep] &&
                formSteps[currentStep].hideButtons &&
                '!hidden',
              hideButtons && '!hidden',
              buttonStyleOverride,
            )}
          >
            {currentStep !== undefined &&
              formSteps &&
              currentStep > 0 &&
              currentStep < totalSteps &&
              !formSteps[currentStep].hideBackButton && (
                <div className="w-full">
                  <Button
                    label="Go back"
                    variant={ButtonVariant.Large}
                    color={ButtonColor.Blue}
                    font={ButtonFont.Body1}
                    responsiveness={ButtonResponsiveness.Full}
                    onClick={handlePrevStep}
                  />
                </div>
              )}

            <div
              className={clsx(
                currentStep &&
                  formSteps &&
                  currentStep > 0 &&
                  currentStep < totalSteps
                  ? 'w-full'
                  : 'w-full min-w-[240px]',
              )}
            >
              {/* TO DO: rename and refactor submitting label / disable submit logic in button */}
              <Button
                label={
                  currentStep && formSteps
                    ? formSteps[currentStep].buttonLabel
                    : buttonLabel
                }
                variant={ButtonVariant.Large}
                color={ButtonColor.Solid}
                font={ButtonFont.Body1}
                responsiveness={ButtonResponsiveness.Full}
                type="submit"
                isSubmitting={disableSubmit}
                disabled={disableSubmit || isDisabled}
                submittingLabel={submittingLabel}
                onClick={
                  currentStep === undefined || currentStep === totalSteps - 1
                    ? handleSubmit
                    : handleNextStep
                }
              />
            </div>
            {allowCancel && (
              <Button
                label={cancelLabel || 'Cancel'}
                variant={ButtonVariant.Large}
                color={ButtonColor.Blue}
                font={ButtonFont.Body1}
                textColor={ButtonColor.Blue}
                responsiveness={ButtonResponsiveness.Full}
                onClick={handleCancel}
                styleOverride="!mt-12"
                disabled={disableSubmit}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
