'use client'

import clsx from 'clsx'
import React from 'react'
import { useState } from 'react'
import '@/components/tokens/Button'
import {
  ButtonColor,
  ButtonVariant,
  ContentNodeType,
  FileExtended,
  Gate,
  Hub,
  ListBoxView,
  QueueItem,
  Release,
} from '@/lib/types'
import Button from './Button'
import Icon from './Icon'

type EllipsisProps = {
  options: { [key: string]: option }
  value:
    | FileExtended
    | Gate
    | Release
    | Hub
    | Release[]
    | QueueItem
    | number
    | undefined
  parentIndex?: number
  total?: number
  fileType?: string
  lastParentIndex?: number
  variant?:
    | 'default'
    | 'gridItem'
    | 'hubHeader'
    | 'releaseHeader'
    | 'queueItem'
    | string
  align?: 'left' | 'right'
  view?: ListBoxView
}

type option = {
  label: string
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any) => void
  variant?: string // Refactor to enum
  align?: string
}

export default function Ellipsis({
  options,
  value,
  parentIndex = 0,
  fileType,
  lastParentIndex,
  variant = 'default',
  align,
}: EllipsisProps) {
  const [showOptions, setShowOptions] = useState(false)

  const handleOptionExecute = (option: string, parentIndex: number) => {
    const selectedOption = options[option]
    selectedOption?.callback(value, parentIndex || 0)
    setShowOptions(false)
  }

  const optionsClone = { ...options }

  if (fileType !== ContentNodeType.Gate) {
    // THIS GATE ADD / REMOVE LOGIC SHOUlD BE MORE GENERIC
    delete optionsClone.delete
    delete optionsClone.download
  }

  if (fileType === ContentNodeType.Gate) {
    delete optionsClone.remove
    delete optionsClone.moveUp
    delete optionsClone.moveDown
  }

  if (parentIndex === 0) {
    delete optionsClone.moveUp
  }

  if (parentIndex === lastParentIndex) {
    delete optionsClone.moveDown
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */

  const handleHideOptions = (e: any) => {
    e.stopPropagation()
    setShowOptions(false)
  }

  const handleShowOptions = (e: any) => {
    e.stopPropagation()
    setShowOptions(true)
  }

  return (
    <div className="relative ">
      <div>
        <section className="cursor-pointer">
          {variant === 'gridItem' && (
            <Button
              className="hover:border-10 z-[9] hover:opacity-80"
              icon="ellipsis"
              variant={ButtonVariant.Square}
              color={ButtonColor.White}
              onClick={(e) => {
                handleShowOptions(e)
              }}
            />
          )}

          {variant === 'hubHeader' && (
            <Button
              className="z-[9] hover:opacity-80"
              icon="queue"
              variant={ButtonVariant.Square}
              color={ButtonColor.Black}
              onClick={(e) => {
                handleShowOptions(e)
              }}
            />
          )}

          {variant === 'header' && (
            <div className="md:hover:opacity-70">
              <Button
                className="z-[9] !border-[10px] hover:!opacity-70"
                icon="queue"
                variant={ButtonVariant.Square}
                color={ButtonColor.Black}
                styleOverride=" hover:opacity-70"
                onClick={(e) => {
                  handleShowOptions(e)
                }}
              />
            </div>
          )}
          {variant === 'queueItem' && (
            <div
              className="hitBox20 "
              onClick={(e) => {
                handleShowOptions(e)
              }}
            >
              <Icon name="ellipsis" width={20} height={20} className="white" />
            </div>
          )}
          {variant === 'default' && (
            <div
              className="hitBox20 "
              onClick={(e) => {
                handleShowOptions(e)
              }}
            >
              <Icon name="ellipsis" width={20} height={20} className="black" />
            </div>
          )}
        </section>

        {showOptions && (
          <div>
            <div
              onTouchStart={(e) => {
                e.stopPropagation()
                handleHideOptions(e)
              }}
              // onClick={(e) => {
              //   e.stopPropagation()
              //   handleHideOptions(e)
              // }}
              className=" ellipsis-bg-trigger fixed left-0 top-0 z-10 h-[100vh] w-[100vw] md:hidden"
            ></div>
            <section
              className={clsx(
                'border-10 absolute  z-[10] w-min min-w-[92px] border-1 border-solid bg-white ',
                variant === 'gridItem' && 'bottom-0 border-white',
                variant !== 'gridItem' && 'top-0',
                align === 'right' && 'left-0',
                align === 'left' && ' right-0 ',
              )}
              onMouseLeave={() => setShowOptions(false)}
            >
              <ul className="border-1 border-white bg-white p-4">
                {Object.keys(optionsClone).map((option, index) => {
                  if (option === 'divider') {
                    return (
                      <li
                        key={index}
                        className={clsx(' my-[8px] border-b-1 border-grey-10')}
                      ></li>
                    )
                  }

                  return (
                    <li
                      key={index}
                      value={options[option].type}
                      className={clsx(
                        'body-1 z-[51] cursor-pointer whitespace-nowrap px-8 py-4 outline-none hover:bg-blue-15',
                        variant === 'queueItem' && 'text-black',
                      )}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleOptionExecute(option, parentIndex)
                      }}
                    >
                      {options[option].label}
                    </li>
                  )
                })}
              </ul>
            </section>
          </div>
        )}
      </div>
    </div>
  )
}
