'use client'

import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  FormField,
  ImageCropProps,
} from '@/lib/types'
import Button from './tokens/Button'
import RangeInput from './tokens/RangeInput'

type ImageUploaderProps = FormField & {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCroppedImage?: (data: {
    imageSrc: string
    croppedAreaPixels: Area
    file: Blob | MediaSource | undefined | File
  }) => void
  value: ImageCropProps
  isRound?: boolean
  aspect?: number
  uploadType?: string
  imageFieldReset?: () => void
}

export default function ImageUploader({
  label,
  hint,
  handleChange,
  handleCroppedImage,
  value,
  error,
  isRound,
  aspect,
  uploadType,
  imageFieldReset,
  inputProps,
}: ImageUploaderProps) {
  const inputRef = inputProps?.inputRef
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource>()

  const [preview, setPreview] = useState<string | undefined>(
    value?.croppedImage || value?.file || value?.imageSrc || value || undefined,
  )

  const [crop, setCrop] = useState<Point>({
    x: value?.croppedAreaPixels?.width || 0,
    y: value?.croppedAreaPixels?.height || 0,
  })

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>(
    value?.croppedAreaPixels || undefined,
  )

  const [zoom, setZoom] = useState(1)
  useEffect(() => {
    if (uploadType !== 'article' && value?.croppedImage) {
      setPreview(value.croppedImage.preview)
    }

    if (uploadType === 'imageBlock' && value?.file) {
      setPreview(value.file)
    }
  }, [])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    //first step
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)

      return
    }

    if (inputProps?.fileSizeLimit) {
      if (e.target.files[0].size > inputProps.fileSizeLimit) {
        alert(
          `File size must be less than ${inputProps.fileSizeLimit / 1000000}mb`,
        )
        setSelectedFile(undefined)

        return
      }
    }

    setSelectedFile(e.target.files[0])
    handleChange && handleChange(e)
  }

  useEffect(() => {
    //second step
    if (!selectedFile && !preview) {
      setPreview(undefined)

      return
    }

    if (!preview && selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
    }

    // return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile, preview])

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      //third step
      setCroppedAreaPixels(croppedAreaPixels)

      if (preview && croppedAreaPixels) {
        const data = {
          imageSrc: preview,
          croppedAreaPixels,
          file: selectedFile,
        }

        handleCroppedImage && handleCroppedImage(data)
      }
    },
    [preview],
  )

  const handleReset = () => {
    imageFieldReset && imageFieldReset()
    setPreview(undefined)
    setSelectedFile(undefined)
    setCroppedAreaPixels(undefined)

    if (value?.croppedImage) {
      value.croppedImage = undefined
    }

    inputRef?.current?.click()
  }

  return (
    <div
      className={clsx(
        croppedAreaPixels && 'h-[340px] ',
        'mb-28 w-[240px]',
        uploadType === 'article' && 'h-[288px] w-full',
      )}
    >
      <p className="ui-1 mb-8">{label}</p>

      <div className={clsx((preview || value?.croppedImage) && 'hidden')}>
        <label
          htmlFor="file-upload"
          className={clsx(
            isRound && 'rounded-full',
            'body-1 flex h-[232px]  w-full cursor-pointer items-center justify-center border-1 text-grey-45',
            error ? '!border-red-55' : 'border-grey-45',
          )}
          ref={inputRef || undefined}
        >
          + Upload a JPG or PNG
        </label>
        <input
          id="file-upload"
          className="hidden"
          type="file"
          name="artwork"
          onClick={(e) => (e.currentTarget.value = '')}
          onChange={(e) => onSelectFile(e)}
          accept=".jpg,.png,.jpeg"
        />
      </div>

      {(preview || value?.croppedImage) && (
        <div
          className={clsx(
            'relative h-[240px] w-[240px]',
            uploadType === 'article' && 'h-[288px] w-[524px]',
          )}
        >
          {/* <div className="imageBorder h-full w-full"> */}
          <div className=" h-full w-full">
            <Cropper
              image={preview || value.croppedImage.preview}
              style={{
                containerStyle: {
                  outlineOffset: '-1px',
                  outline: '1px solid rgba(0, 0, 0, 0.1)',
                },
              }}
              crop={crop}
              zoom={zoom}
              aspect={(uploadType === 'article' && 16 / 9) || aspect || 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              cropShape={isRound ? 'round' : 'rect'}
              nonce="image"
            />
          </div>

          <div className="align-center flex w-full items-center py-8">
            <RangeInput
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e) => setZoom(Number(e.target.value))}
            />
          </div>

          <Button
            variant={ButtonVariant.Large}
            label="+ Replace image"
            color={ButtonColor.Grey}
            textColor={ButtonColor.Grey}
            responsiveness={ButtonResponsiveness.Full}
            font={ButtonFont.Body1}
            onClick={handleReset}
          />
        </div>
      )}
      {/* {error && !preview && <span className="caption-1 pt-8">{error[0]}</span>} */}

      {hint && !selectedFile && (
        <p className="body-1 mt-8 text-grey-45">{hint}</p>
      )}
    </div>
  )
}
