export const formatCatalogNumber = (inputString: string) => {
  let formattedCatalogString = inputString.replace(/\s+/g, '-')

  formattedCatalogString = formattedCatalogString.replace(/[^\w\s-]/g, '')
  formattedCatalogString = formattedCatalogString.toUpperCase()
  formattedCatalogString = formattedCatalogString.replace(/-+/g, '-')

  if (formattedCatalogString.startsWith('-')) {
    formattedCatalogString = formattedCatalogString.substring(1)
  }

  return formattedCatalogString
}
