'use client'

import clsx from 'clsx'
import { FormField } from '@/lib/types'
import Icon from './Icon'

type SelectProps = React.HTMLAttributes<HTMLSelectElement> &
  FormField & {
    options?: { label: string; value: string; disabled?: boolean }[] | undefined
    variant: 'filter' | 'standard' | 'walletSelect'
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  }

export default function Select(props: SelectProps) {
  const {
    name,
    options,
    handleChange,
    value,
    variant = 'standard',
    placeholder,
    required,
    label,
    error,
    hint,
    hide,
    inputProps,
    styleOverride,
  } = props

  return (
    <div className={clsx(' w-full', hide && 'hidden')}>
      {label && (
        <div className="body-1 flex flex-col ">
          <label htmlFor={name} className="!ui-1 pb-8">
            {label}
            {required && <span className=""> *</span>}
          </label>
        </div>
      )}
      <div
        className={clsx(
          variant === 'filter' && '',
          'border-box relative ',
          // label && 'mt-[8px]',
        )}
      >
        <select
          className={clsx(
            variant === 'filter' &&
              'border-box  w-full  pb-[0.5px] pl-[10px] md:w-[184px] lg:w-[200px]',
            variant === 'standard' && ' h-[40px]  w-full pb-0 pl-12',
            variant === 'walletSelect' &&
              'h-[40px] w-[100px] border-blue-82 pb-0 pl-12 !text-blue-82',
            error ? 'border-1 border-red-55' : 'border-blue-55 border-1',
            'body-1 appearance-none rounded-none bg-white bg-opacity-0 text-14 focus:outline-none',
            inputProps?.accentColor && value && 'border-black text-blue-82',
            styleOverride,
          )}
          name={name}
          id={name}
          value={value as string | number | readonly string[] | undefined}
          onChange={handleChange}
        >
          {placeholder && (
            <option
              value=""
              className={clsx(
                'body-1 hidden bg-white text-14',
                variant === 'walletSelect' && 'text-blue-82',
              )}
            >
              {placeholder}
            </option>
          )}
          {options?.map((option, index) => {
            return (
              <option
                value={option.value}
                key={index}
                className={clsx(
                  'body-1 border-2  bg-white text-14',
                  variant === 'walletSelect' && 'text-blue-82',
                )}
                disabled={option.disabled}
              >
                {variant === 'filter' ? `${option.label}` : option.label}
              </option>
            )
          })}
        </select>
        <Icon
          className={clsx(
            variant === 'walletSelect' ? 'blue-82' : 'black',
            ' pointer-events-none absolute right-[8px] top-1/2',
            variant === 'filter' &&
              'top-[53%] -translate-y-1/2 transform pb-[0.5px]',
            variant === 'walletSelect' && ' -translate-y-1/2 transform',
            variant === 'standard' &&
              ' top-[45%] -translate-y-[33.33%] transform ',
          )}
          width={20}
          height={20}
          name="chevronDownHeavy"
        />
      </div>
      {error && <p className="caption-1 pt-[8px]">{error[0]}</p>}
      {hint && (
        <p className={clsx('caption-1 text-grey-45', !error && 'pt-[8px]')}>
          {hint}
        </p>
      )}
    </div>
  )
}
